<template>
  <v-app ref="appRoot" v-bind:style="{ transform: 'scale('+appScale+')'}">
      <Header/>


    <v-main fluid>
        <div class="mainContainer">
            <router-view/>
        </div>
    </v-main>
  </v-app>
</template>
<style lang="scss">
@import "@/assets/scss/main.scss";
</style>
<script>
import Header from './components/header';


export default {
  name: 'App',

  components: {
    Header,
  },

  data: () => ({
    //
    appScale: 1
  }),
  mounted(){
      let monScale = 1/window.devicePixelRatio;
      //this.appScale = monScale;
  },
};
</script>
