import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"indigo appHeader"},[_vm._m(0),_c('p',{staticClass:"headerLogoText text-h5 text-left white--text"},[_vm._v("Kudos Console")]),_c('div',{attrs:{"id":"headerRightWrapper"}},[_c('div',{attrs:{"id":"headerIcon"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({attrs:{"href":"https://app.slack.com/client/T02FS9HLW/CD2RZR6H0","target":"_slack"}},'a',attrs,false),on),[_c(VIcon,{staticClass:"white--text",attrs:{"large":""}},[_vm._v("mdi-slack")])],1)]}}])},[_c('span',[_vm._v("Get support service")])]),_c(VMenu,{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({},'a',attrs,false),on),[_c('img',{staticClass:"rounded-circle z-depth-0",attrs:{"src":_vm.avatar,"height":"30"}})])]}}])},[_c(VContainer,{staticClass:"userMenuContainer white text-black text-left text-body-2 pt-8 pb-4"},[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c('img',{staticClass:"rounded-circle z-depth-0",attrs:{"src":_vm.avatar,"width":"100%"}})]),_c(VCol,{attrs:{"cols":"8"}},[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(_vm._s(_vm.name))]),_c('p',[_vm._v(_vm._s(_vm.email))]),_c('p',[_vm._v(_vm._s(_vm.title))])]),_c(VCol,{staticClass:"text-right pr-8",attrs:{"cols":"12"}},[_c('a',{staticClass:"text-button",on:{"click":_vm.logout}},[_vm._v("logout")])])],1)],1)],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":""}},[_c('img',{attrs:{"alt":"SCMP logo","src":"assets/img/logo_icon.png"}})])
}]

export { render, staticRenderFns }