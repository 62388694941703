import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLoggedIn: false,
        userProfile: {
            name: "",
            email: "",
            pic: "",
            accessTimeout: ""
        }
    },
    mutations: {
        setUserProfile(state, profile) {
            this.state.userProfile = Object.assign({}, this.state.userProfile, profile)
        }
    },
    actions: {
        async fetchUser(context) {
            let response;
            try {
                response = await axios.get(process.env.VUE_APP_SERVER_PATH + "/user/profile");
            } catch (e) {
                window.location.href = process.env.VUE_APP_SERVER_PATH;
            };

            if (response) {
                if (response.data.principal instanceof Object) {
                    let userInfo = {
                        username: response.data.principal.attributes.name,
                        email: response.data.principal.attributes.email,
                        pic: response.data.principal.attributes.picture
                    };
                    setCookie("userInfo", JSON.stringify(userInfo), 3600);
                    // console.log(getCookie("userInfo") );
                    // console.log(document.cookie);
                    context.commit('setUserProfile', JSON.parse(getCookie("userInfo")));
                    return new Promise(function(resolve, reject) {
                        resolve(context.state.userProfile);
                    })
                }
            }
            return new Promise(function(resolve, reject) {
                resolve(null);
            })
        },
        getCallbackData(value) {
            return value;
        },
        logout(context) {
            console.log(window.localStorage);
            window.localStorage.clear();
            window.location.href = process.env.VUE_APP_SERVER_PATH + "/profile/logout";
        }
    },
    modules: {}
})
