<template>
    <header class="indigo appHeader">
        <a href>
            <img alt="SCMP logo" src="assets/img/logo_icon.png">
        </a>
        <p class="headerLogoText text-h5 text-left white--text">Kudos Console</p>

        <div id="headerRightWrapper">
            <div id="headerIcon">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <a href="https://app.slack.com/client/T02FS9HLW/CD2RZR6H0"  target="_slack" v-bind="attrs" v-on="on">
                            <v-icon large class="white--text">mdi-slack</v-icon>
                        </a>
                    </template>
                    <span>Get support service</span>
                </v-tooltip>
                <v-menu
                    bottom
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <a v-bind="attrs"  v-on="on">
                          <img :src="avatar" class="rounded-circle z-depth-0" height="30">
                      </a>
                    </template>
                    <v-container class="userMenuContainer white text-black text-left text-body-2 pt-8 pb-4">
                        <v-row>
                            <v-col cols="4">
                                <img :src="avatar" class="rounded-circle z-depth-0" width="100%">
                            </v-col>
                            <v-col cols="8">
                                <p class="font-weight-bold mb-0">{{name}}</p>
                                <p>{{email}}</p>
                                <p>{{title}}</p>
                            </v-col>
                            <v-col cols="12" class="text-right pr-8">
                                <a class="text-button" @click="logout">logout</a>
                            </v-col>
                        </v-row>
                    </v-container>
                  </v-menu>
            </div>
        </div>
    </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
      return {
          avatar: "",
          name: "",
          title: "",
          email: ""
      };
  },
  created(){
      this.initHeader();
  },
  methods: {
      logout(){
          let localStorage = window.localStorage;

          localStorage.clear();
          window.location.href= process.env.VUE_APP_API_PATH +"/logout";
      },
      initHeader(){
          let localStorage = window.localStorage;

          if( process.env.NODE_ENV == "uat" ){
              this.avatar = " https://mdbootstrap.com/img/Photos/Avatars/avatar-7.jpg";
          }else{
              this.avatar = localStorage.user_pic;
          }
          this.name = localStorage.user_name;
          this.email = localStorage.user_email;
      }
  }
}
</script>
