import axios from "axios";
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    },
    {
        path: '/noright',
        name: 'noRight',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/noRight.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_BASE_PATH,
    routes
})

router.beforeEach((to, from, next) => {

    let localStorage = window.localStorage,
        searchParam = new URLSearchParams(window.location.search),
        refreshForce = searchParam.get('refresh');
    //console.log( localStore );

    if (to.matched.some(record => record.meta.requiresAuth) ) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

        if( refreshForce == 'true' ){
            next({ name: 'Login' });
        }else{
            let result = "";

            axios
            .post( process.env.VUE_APP_API_PATH +"/session/credentials")
            .then(response => {
                result = response.data

                if(result != "failed"){
                    next()
                }else{
                    next({ name: 'Login' })
                }
            })
            .catch(error => {
                console.log(error)
                //this.errored = true
            })

        }

    } else {
        next() // does not require auth, make sure to always call next()!
    }
})

export default router
