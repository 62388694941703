<template>
    <main class="py-4 pt-3">

        <v-snackbar
          v-model="alertBar"
          :timeout="alertMsgTimeout"
        >
            {{ alertMsg }}
        </v-snackbar>

        <v-dialog
          v-model="dialogCreatedSlides"
          max-width="768px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Created Kudos Slides</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" >
                                <p>
                                    The Kudos Slides show is created. Please check the link of below. <br/>
                                    <a :href="createdSlidesURL" target="_blank">{{createdSlidesURL}}</a>
                                </p>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="dialogCreatedSlides = false; createdSlidesURL = '';"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogDeleteSlides"
          max-width="768px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Delete Slides</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" >
                                <p>
                                    Sure delete kudos slides. the link of below? <br/>
                                    <a :href="deleteSlideURL" target="_blank">{{deleteSlideURL}}</a>
                                </p>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="deleteSlides"
                    >
                        Confirm
                    </v-btn>
                    <v-btn
                        color="primary"
                        text
                        @click="closeConfirmDeleteSlides"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogLoading" width="200" persistent no-click-animation>
            <div class="modalContainer">

                <div class="loadingWrapper py-8 text-center">
                    <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <p class="mt-4 text-center">Loading...</p>
                </div>

            </div>
        </v-dialog>



        <v-container fluid>
            <v-row justify="center">

                <v-col xl="7" lg="8" md="12">

                    <v-toolbar class="userGoalNavs" flat color="transparent" height="auto">
                        <template >
                            <v-tabs active-class="goalNavActive" align-with-title class="indigo" icons-and-text height="auto">
                                <v-tabs-slider color="indigo"></v-tabs-slider>
                                <v-tab  @click="toKudosTab" class="white--text">
                                    Kudos
                                    <v-icon large class="white--text" >fas fa-thumbs-up</v-icon>
                                </v-tab>
                                <v-tab  @click="toSlidesTab" class="white--text">
                                    Slides
                                    <v-icon large class="white--text" >far fa-file-powerpoint</v-icon>
                                </v-tab>
                                <v-tab  @click="toUserConfigTab" class="white--text" v-if="staffInfoObj.user_admin == 1" >
                                    User Config
                                    <v-icon large class="white--text" >fas fa-user-edit</i></v-icon>
                                </v-tab>
                                <v-tab  @click="toLogConfigTab" class="white--text" v-if="staffInfoObj.user_admin == 1">
                                    Kudos Activity
                                    <v-icon large class="white--text" >fas fa-clipboard-list</v-icon>
                                </v-tab>
                            </v-tabs>
                        </template>
                    </v-toolbar>

                    <v-tabs-items  class="userGoalNavItems" v-model="pageTabs">
                        <v-tab-item>
                            <div class="tabPageWindow py-0 px-4">
                                <v-row class="">
                                    <v-col cols="2">
                                        <v-text-field
                                            v-model="kudosTableSearch"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="2">
                                        <v-menu
                                            v-model="dateRangePicker"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="dateRangeDisplay"
                                                    v-mask="'####-##-## ~ ####-##-##'"
                                                    label="Date Range"
                                                    append-icon="mdi-calendar"
                                                    @click:append="dateRangePicker= true"
                                                    @change="dateRangeInput"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="dateRange"
                                                range
                                                @input="closeDateRangePicker"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="text-right">
                                        <v-dialog
                                          v-model="dialogLeaderBoard"
                                          max-width="768px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="excel ml-2 indigo" dark @click="showLeaderBoard" v-bind="attrs" v-on="on">
                                                    <v-icon class="mr-2">fas fa-award</v-icon>
                                                    Leaderboard
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-title>
                                                    <span class="text-h5">Kudos LeaderBoard</span>
                                                </v-card-title>

                                                <v-card-text>
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="12" sm="4" >
                                                                <v-menu
                                                                    v-model="leaderBoardDateRangePicker"
                                                                    :close-on-content-click="false"
                                                                    :nudge-right="40"
                                                                    transition="scale-transition"
                                                                    offset-y
                                                                    min-width="auto"
                                                                >
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-text-field
                                                                            v-model="leaderBoardDateRangeDisplay"
                                                                            v-mask="'####-##-## ~ ####-##-##'"
                                                                            label="Date Range"
                                                                            append-icon="mdi-calendar"
                                                                            @click:append="leaderBoardDateRangePicker= true"
                                                                            @blur="leaderBoardDateRangeInput"
                                                                        ></v-text-field>
                                                                    </template>
                                                                    <v-date-picker
                                                                        v-model="leaderBoardDateRange"
                                                                        range
                                                                        @input="closeLeaderBoardDateRangePicker"
                                                                    ></v-date-picker>
                                                                </v-menu>
                                                            </v-col>
                                                            <v-col cols="12" sm="4" >
                                                                <v-select
                                                                    :items="leaderboardDisplayTopOption"
                                                                    v-model="selectedLeaderboardDisplayTop"
                                                                    label="Select Display"
                                                                    @change="GetLeaderboardData"
                                                                ></v-select>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="10"><p class="text-h6 mb-0">Top Sender</p></v-col>
                                                            <v-col cols="2" class="text-right">
                                                                <v-btn dark class="mr-5 indigo"  @click="copyLeaderboardContent('leaderboardFrom')">
                                                                    <v-icon left dark >
                                                                        fas fa-copy
                                                                    </v-icon>
                                                                    Copy
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <div ref="leaderboardFrom" v-html="leaderboardFrom" class="previewBox pa-4 mb-4"></div>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="10"><p class="text-h6 mb-0">Top Recipient</p></v-col>
                                                            <v-col cols="2" class="text-right">
                                                                <v-btn dark class="mr-5 indigo" @click="copyLeaderboardContent('leaderboardTo')">
                                                                    <v-icon left dark >
                                                                        fas fa-copy
                                                                    </v-icon>
                                                                    Copy
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <div ref="leaderboardTo" v-html="leaderboardTo" class="previewBox pa-4 mb-4"></div>
                                                            </v-col>

                                                        </v-row>

                                                    </v-container>
                                                </v-card-text>

                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        color="primary"
                                                        text
                                                        @click="closeLeaderBoardDialog"
                                                    >
                                                        close
                                                    </v-btn>
                                                    <!-- <v-btn
                                                        color="primary"
                                                        text
                                                        @click="saveUser"
                                                    >
                                                        Save
                                                    </v-btn> -->
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>

                                        <v-btn class="excel ml-2 indigo" dark @click="ExportKudosData">
                                            <v-icon class="mr-2">fas fa-file-excel</v-icon>
                                            Export Data
                                        </v-btn>

                                        <v-btn class="excel ml-2 indigo" dark @click="ExportKudosSlides">
                                            <v-icon class="mr-2">fas fa-file-powerpoint</v-icon>
                                            Export Slides
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-data-table
                                            show-select
                                            v-model="selectedKudosObj"
                                            :search="kudosTableSearch"
                                            :headers="kudos_headers"
                                            :items="kudosObj"
                                            item-key="id"
                                            :sort-by="['kudos_date']"
                                            sort-desc
                                            :items-per-page = "15"
                                            :footer-props="{'items-per-page-options': tableRowsPerPageItems}"
                                            class="elevation-1"
                                        >
                                            <template v-slot:item.kudos_desc="{ item }">
                                                <div v-html="item['kudos_desc_html']"></div>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-tab-item>

                        <v-tab-item>
                            <div class="tabPageWindow py-0 px-4">

                                <v-row  class="">
                                    <v-col cols="2">
                                        <v-text-field
                                            v-model="slidesTableSearch"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="10" class="text-right">
                                        <v-btn class="ml-2 indigo" dark target="_blank" :href="'https://docs.google.com/presentation/d/'+ slidesTemplateURL">
                                            <v-icon class="mr-2">fas fa-file</v-icon>
                                            Slides Template
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-data-table
                                            :search="slidesTableSearch"
                                            :headers="slides_headers"
                                            :items="slidesObj"
                                            item-key="id"
                                            :sort-by="['slide_createdate']"
                                            sort-desc
                                            :items-per-page = "15"
                                            :footer-props="{'items-per-page-options': tableRowsPerPageItems}"
                                            class="elevation-1"
                                        >
                                            <template v-slot:item.slide_uuid="{ item }">
                                                <a :href="slidesURLPrefix + item.slide_uuid" target="_blank">
                                                    {{ slidesURLPrefix + item.slide_uuid }}
                                                </a>
                                            </template>
                                            <template v-slot:item.actions="{ item }">
                                                <v-icon
                                                  class="mx-2"
                                                  @click="showConfimDeleteSlides(item)"
                                                >
                                                  fas fa-trash
                                                </v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-tab-item>
                        <v-tab-item  v-if="staffInfoObj.user_admin == 1">
                            <div class="tabPageWindow py-0 px-4">

                                <v-row class="">

                                    <v-col cols="2">
                                        <v-text-field
                                            v-model="userConfigTableSearch"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                    </v-col>

                                    <v-col class="text-right">
                                        <v-dialog
                                          v-model="dialogUser"
                                          max-width="768px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn dark class="mr-5 indigo" v-bind="attrs" v-on="on">
                                                    <v-icon left dark class="mr-4" >
                                                        fas fa-user-plus
                                                    </v-icon>
                                                    Add User
                                                </v-btn>
                                            </template>
                                            <v-card>
                                                <v-card-title>
                                                    <span class="text-h5">{{ userFormTitle }}</span>
                                                </v-card-title>

                                                <v-form ref="userConfigForm" v-model="userConfigFormValid" >
                                                    <v-card-text>
                                                        <v-container>
                                                            <v-row>
                                                                <v-col cols="12" sm="6" >
                                                                    <v-text-field
                                                                        v-model="editedUserConfigItem.user_email"
                                                                        label="User Email"
                                                                        :rules="emailFieldRules"
                                                                        class="required"
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12" sm="6" >
                                                                    <v-text-field
                                                                        v-model="editedUserConfigItem.user_name"
                                                                        label="User Name"
                                                                        :rules="requiredFieldRules"
                                                                        class="required"
                                                                        outlined
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12" sm="4" class="text-left" >
                                                                    <p class="mb-0">Admin</p>
                                                                    <v-checkbox
                                                                        class="mt-1"
                                                                        v-model="editedUserConfigItem.user_admin"
                                                                        label="YES"
                                                                    ></v-checkbox>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-card-text>

                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            color="primary"
                                                            text
                                                            @click="closeUserDialog"
                                                        >
                                                            Cancel
                                                        </v-btn>
                                                        <v-btn
                                                            color="primary"
                                                            text
                                                            @click="saveUser"
                                                        >
                                                            Save
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-form>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>

                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-data-table
                                            :search="userConfigTableSearch"
                                            :headers="userConfig_headers"
                                            :items="userConfigObj"
                                            item-key="subID"
                                            :sort-by="['name']"
                                            :items-per-page = "15"
                                            :footer-props="{'items-per-page-options': tableRowsPerPageItems}"
                                            class="elevation-1"
                                        >

                                            <template v-slot:item.user_admin="{ item }">
                                                <span v-if="item.user_admin">YES</span>
                                                <span v-else>NO</span>
                                            </template>
                                            <template v-slot:item.actions="{ item }">
                                                <v-icon
                                                  class="mx-2"
                                                  @click="editUser(item)"
                                                >
                                                  fas fa-edit
                                                </v-icon>
                                                <v-icon
                                                  v-if="item.user_status == 'ACTIVE'"
                                                  class="mx-2"
                                                  @click="changeUserStatus(item, 'DISABLED')"
                                                >
                                                  fas fa-user-times
                                                </v-icon>
                                                <v-icon
                                                  v-else
                                                  class="mx-2"
                                                  @click="changeUserStatus(item, 'ACTIVE')"
                                                >
                                                  fas fa-user-check
                                                </v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-tab-item>

                        <v-tab-item  v-if="staffInfoObj.user_admin == 1">
                            <div class="tabPageWindow py-0 px-4">

                                <v-row>
                                    <v-col cols="2">
                                        <v-text-field
                                            v-model="kudosActivityTableSearch"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col>
                                        <v-data-table
                                            :search="kudosActivityTableSearch"
                                            :headers="kudosActivity_headers"
                                            :items="kudosActivityObj"
                                            :items-per-page = "15"
                                            item-key="subID"
                                            :footer-props="{'items-per-page-options': tableRowsPerPageItems}"
                                            sort-by="kudos_activity_createdate"
                                            sort-desc
                                            class="elevation-1">
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-tab-item>

                    </v-tabs-items>

                </v-col>

            </v-row>

        </v-container>
    </main>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import moment from 'moment';
import { escapeForSlack, escapeForSlackWithMarkdown } from 'slack-hawk-down'
const { toHTML } = require("slack-markdown");

export default {
    name: 'Home',
    components: {

    },
    data() {
        return {
            isInited: false,
            alertBar: false,
            alertMsg: "",
            alertMsgTimeout: -1,
            requiredFieldRules:[
                v => !!v || 'This Field is required',
                v => (v && !!v.toString().trim()) || 'This Field is required',
            ],
            emailFieldRules: [
                v => !!v || 'E-mail is required',
                v => (v && !!v.toString().trim()) || 'E-mail is required',
                v => (v && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v)) || 'E-mail must be valid',
            ],
            slidesURLPrefix: "https://docs.google.com/presentation/d/",
            dialogCreatedSlides: false,
            createdSlidesURL: "",
            slidesTemplateURL: "",
            dialogDeleteSlides: false,
            deleteSlideURL: "",
            deleteSlideInfo:{},
            deleteSlideIndex: -1,
            dialogLoading: false,
            dialogUser: false,
            dialogLeaderBoard: false,
            userConfigFormValid: true,
            dateRangePicker: false,
            dateRange: [],
            dateRangeDisplay: "",
            leaderBoardDateRangePicker: false,
            leaderBoardDateRange: [],
            leaderBoardDateRangeDisplay: "",
            pageTabs: 0,
            kudosActivityObj:{},
            kudosTableSearch: "",
            slidesTableSearch: "",
            userConfigTableSearch: "",
            kudosActivityTableSearch: "",
            tableRowsPerPageItems: [15, 50, 100, -1],
            kudos_headers:[
                { text: 'Kudos Date', value: 'kudos_date', width: '15%'},
                { text: 'Kudos From', value: 'kudos_from' , width: '15%'},
                { text: 'Kudos To', value: 'kudos_to_group' , width: '35%'},
                { text: 'Kudos Message', value: 'kudos_desc',  width: '35%'},
                // { text: 'Private', value: 'kudos_is_private',  width: '10%'},
            ],
            slides_headers:[
                { text: 'Create Date', value: 'slide_createdate'},
                { text: 'Create By', value: 'slide_createdby_name'},
                { text: 'URL', value: 'slide_uuid'},
                { text: 'Action', value: 'actions',  sortable: false }
            ],
            userConfig_headers:[
                { text: 'Full Name', value: 'user_name', },
                { text: 'Account Status', value: 'user_status' },
                { text: 'Admin', value: 'user_admin' },
                { text: 'Action', value: 'actions',  sortable: false }
            ],
            kudosActivity_headers:[
                { text: 'Create Date', align: 'start', value: 'kudos_activity_createdate', width: '20%'},
                { text: 'User Name', value: 'kudos_activity_user_name', width: '10%'},
                { text: 'Command', value: 'kudos_activity_text' , width: '70%'},
            ],
            staffInfoObj: {
                "user_email" : "",
                "user_pic" : "",
                "user_name" : "",
                "user_admin" : 0
            },
            kudosObj: [],
            slidesObj: [],
            selectedKudosObj: [],
            userConfigObj: [],
            editedUserConfigIndex: -1,
            editedUserConfigItem:{
                "user_id":"",
                "user_name": "",
                "user_email": "",
                "user_status": "ACTIVE",
                "user_admin": 0
            },
            defaultUserConfigItem:{
                "user_id":"",
                "user_name": "",
                "user_email": "",
                "user_status": "ACTIVE",
                "user_admin": 0
            },
            selectedLeaderboardDisplayTop: 3,
            leaderboardFrom: "",
            leaderboardTo:"",
            leaderboardDisplayTopOption:[
                { text: "Top 3", value: 3 },
                { text: "Top 5", value: 5 },
                { text: "Top 10", value: 10 },
            ]
        };
    },
    computed: {
        userFormTitle () {
            return this.editedUserIndex === -1 ? 'New User' : 'Edit User'
        },
    },
    created(){
        this.initApp();
    },
    mounted(){

    },
    watch:{
    },
    methods: {
        closeLeaderBoardDialog () {
            this.dialogLeaderBoard = false
        },
        deleteSlides(){
            let vm = this,
                uuid = vm.deleteSlideInfo.slide_uuid;

            let data = new FormData(),
                postURL = process.env.VUE_APP_API_PATH +"/exportSlides/delete";

            data.append('uuid', vm.deleteSlideInfo.slide_uuid);

            this.dialogLoading = true;
            axios
            .post(postURL, data)
            .then(response => {
                let result = response.data
                vm.dialogLoading = false;

                vm.slidesObj.splice(vm.deleteSlideIndex, 1)
                vm.closeConfirmDeleteSlides();
            })
            .catch(error => {
                console.log(error)
                //this.errored = true
            })
        },
        showConfimDeleteSlides (item){
            this.deleteSlideIndex = this.slidesObj.indexOf(item)
            this.deleteSlideInfo = Object.assign({}, item)
            this.deleteSlideURL = this.slidesURLPrefix + item.slide_uuid
            this.dialogDeleteSlides = true;
        },
        closeConfirmDeleteSlides(){

            this.dialogDeleteSlides = false;
            this.deleteSlideIndex = -1;
            this.deleteSlideInfo = Object.assign({}, {})
            this.deleteSlideURL = '';
        },
        editUser (item) {
            let vm = this;
            this.editedUserConfigIndex = this.userConfigObj.indexOf(item)
            this.editedUserConfigItem = Object.assign({}, item)
            this.dialogUser = true
        },
        deleteUser (item) {
            this.editedUserConfigIndex = this.userConfigObj.indexOf(item)
            this.editedUserConfigItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        closeUserDialog () {
            this.dialogUser = false
            this.$nextTick(() => {
                this.editedUserConfigItem = Object.assign({}, this.defaultUserConfigItem)
                this.editedUserConfigIndex = -1
            })
            this.$refs.userConfigForm.resetValidation();
        },
        changeUserStatus (item, status){
            let vm = this;

            this.editedUserConfigIndex = this.userConfigObj.indexOf(item)
            item.user_status = status;
            this.editedUserConfigItem = Object.assign({}, item)

            let data = new FormData(),
                postURL = process.env.VUE_APP_API_PATH +"/user/updateStatus";

            data.append('user_id', this.editedUserConfigItem.user_id);
            data.append('user_status', this.editedUserConfigItem.user_status);

            this.dialogLoading = true;
            axios
            .post(postURL, data)
            .then(response => {
                let resultData = response.data,
                    updateObj = vm.editedUserConfigItem;

                Object.assign(vm.userConfigObj[vm.editedUserConfigIndex], vm.editedUserConfigItem)
                vm.$nextTick(() => {
                    vm.editedUserConfigItem = Object.assign({}, vm.defaultUserConfigItem)
                    vm.editedUserConfigIndex = -1
                })
                vm.dialogLoading = false;

            })
            .catch(error => {
                console.log(error)
                //this.errored = true
            })
        },
        saveUser () {
            let vm = this;

            if( vm.$refs.userConfigForm.validate() ){

                let data = new FormData(),
                    postURL = process.env.VUE_APP_API_PATH +"/user/save";

                if( this.editedUserConfigItem.user_id != "" && this.editedUserConfigItem.user_id != null ){
                    data.append('user_id', this.editedUserConfigItem.user_id);
                }

                data.append('user_name', this.editedUserConfigItem.user_name);
                data.append('user_email', this.editedUserConfigItem.user_email);
                data.append('user_admin', this.editedUserConfigItem.user_admin);

                this.dialogLoading = true;
                axios
                .post(postURL, data)
                .then(response => {
                    let resultData = response.data,
                        updateObj = vm.editedUserConfigItem;

                    if( resultData == "exist" ){

                        vm.alertSnack('The user email is existed in database');

                    }else{
                        if (vm.editedUserConfigIndex > -1) {
                            Object.assign(vm.userConfigObj[vm.editedUserConfigIndex], vm.editedUserConfigItem)
                        } else {
                            vm.userConfigObj.push(vm.editedUserConfigItem)
                        }
                        vm.closeUserDialog()
                    }
                    vm.dialogLoading = false;

                })
                .catch(error => {
                    console.log(error)
                    //this.errored = true
                })

            }
        },
        async toKudosTab(){
            let vm = this;

            if( vm.pageTabs != 0 ){
                await vm.GetLeaderboardData();
                await vm.GetKudosData();
                vm.pageTabs = 0;
            }
        },
        async toSlidesTab(){
            let vm = this;

            if( vm.pageTabs != 1  ){

                vm.dialogLoading = true;
                await axios
                .post(process.env.VUE_APP_API_PATH +"/exportSlides/activity")
                .then(response => {
                    let result = response.data;

                    if( result == "failed" ){
                         window.location.href= process.env.VUE_APP_LOGIN_PATH;
                    }else{

                        vm.dialogLoading = false;
                        vm.slidesObj = result.output;
                        vm.pageTabs = 1;
                    }

                })
                .catch(error => {
                    console.log(error)
                    //this.errored = true
                })
            }
        },
        async toUserConfigTab(){
            let vm = this;

            if( vm.pageTabs != 2 ){

                vm.dialogLoading = true;
                await axios
                .post(process.env.VUE_APP_API_PATH +"/users")
                .then(response => {
                    let result = response.data;

                    console.log(result)

                    if( result == "failed" ){
                         window.location.href= process.env.VUE_APP_LOGIN_PATH;
                    }else{

                        vm.dialogLoading = false;
                        vm.userConfigObj = result;
                        vm.pageTabs = 2;
                    }

                })
                .catch(error => {
                    console.log(error)
                    //this.errored = true
                })

            }

        },
        async toLogConfigTab(){

            let vm = this;

            if( vm.pageTabs != 3 ){
                let postData = new FormData;
                postData.append('days', '30');

                vm.dialogLoading = true;
                await axios
                .post(process.env.VUE_APP_API_PATH +"/kudos/activity", postData)
                .then(response => {
                    let result = response.data;

                    if( result == "failed" ){
                        window.location.href= process.env.VUE_APP_LOGIN_PATH;
                    }else{
                        vm.dialogLoading = false;
                        vm.kudosActivityObj = result;
                        vm.pageTabs = 3;

                    }

                })
                .catch(error => {
                    console.log(error)
                    //this.errored = true
                })

            }

        },
        async GetKudosData(){
            let vm = this,
                data = new FormData();

                data.append('date_from', vm.dateRange[0]);
                data.append('date_to', vm.dateRange[1]);


            vm.dialogLoading = true;
            await
            axios
            .post(process.env.VUE_APP_API_PATH +"/kudos", data)
            .then(response => {
                let result = response.data;
                vm.kudosObj = [];
                vm.selectedKudosObj = [];

                if( result == "failed" ){
                    window.location.href= process.env.VUE_APP_LOGIN_PATH;
                }else{

                    if( result.output.length > 0 ){
                        result.output.forEach(function(element){
                            let contentObj = {},
                                kudos_msg = vm.decodeHtml(escapeForSlack(element.kudos_desc));

                            // contentObj.id = element.kudos_id;
                            contentObj.kudos_date = element.kudos_createdate;
                            contentObj.kudos_from = element.kudos_from_name;
                            //contentObj.kudos_to_group = element.kudos_to_group.replace(",",", ");
                            // contentObj.kudos_is_private = element.kudos_is_private;
                            contentObj.kudos_desc = kudos_msg;
                            contentObj.kudos_desc_html = toHTML( kudos_msg )
                            contentObj.kudos_desc_clear = vm.stripHtml(toHTML( kudos_msg ))

                            contentObj.kudos_to_group = ""
                            let group_list = element.kudos_to_group.split(',')
                            group_list.forEach(function(val, idx){
                                if( !contentObj.kudos_to_group.includes(val) ){
                                    contentObj.kudos_to_group += val +  ', '
                                }                                
                            });

                            contentObj.kudos_to_group = contentObj.kudos_to_group.replace(/,\s*$/, "");
                            vm.kudosObj.push(contentObj);
                        });
                    }

                    vm.dialogLoading = false;
                    if( !vm.isInited ){
                        vm.isInited = true;
                    }

                }
            });
        },
        async loadInitData(){
            let vm = this;

            let today = new Date();
            let monday = vm.formatDate(new Date(today.setDate(today.getDate() - today.getDay() + 1)));
            let friday = vm.formatDate(new Date(today.setDate(today.getDate() - today.getDay() + 5)));

            let day = new Date();
            let firstDay = vm.formatDate(new Date(day.getFullYear(), day.getMonth()));
            let lastDay = vm.formatDate(new Date(day.getFullYear(), day.getMonth() + 1 , 0));

            vm.dateRange.push(monday);
            vm.dateRange.push(friday);
            vm.dateRangeChecking();

            vm.leaderBoardDateRange.push(firstDay);
            vm.leaderBoardDateRange.push(lastDay);
            vm.leaderBoardDateRangeChecking();

            await
            axios
            .post(process.env.VUE_APP_API_PATH +"/getSlidesTemplate")
            .then(response => {
                let result = response.data;
                vm.slidesTemplateURL = result
            });

            // //Debug Console
            // await
            // axios
            // .post(process.env.VUE_APP_API_PATH +"/session/credentials")
            // .then(response => {
            //     let result = response.data;
            //
            //     console.log( result )
            // });

        },
        async initApp(){
            let localStorage = window.localStorage,
                vm = this;

            this.staffInfoObj.user_email = localStorage.user_email;
            if( process.env.NODE_ENV == "uat" ){
                this.staffInfoObj.user_pic = " https://mdbootstrap.com/img/Photos/Avatars/avatar-7.jpg";
            }else{
                this.staffInfoObj.user_pic = localStorage.user_pic;
            }
            this.staffInfoObj.user_name = localStorage.user_name;
            if( localStorage.user_admin == "true" ){
                this.staffInfoObj.user_admin = 1;
            }else{
                this.staffInfoObj.user_admin = 0;
            }

            this.loadInitData();
        },

        closeDateRangePicker(){
            let vm = this;

            if( vm.dateRange.length > 1 ){
                vm.dateRangePicker = false;
                vm.dateRangeChecking();
            }

        },

        dateRangeInput(){
            const vm = this;

            const displayText = vm.dateRangeDisplay;

            if( displayText.includes(" ~ ") && displayText.length >= 23 ){
                vm.dateRange = displayText.split(" ~ ");
                vm.dateRangeChecking();
            }else{
                vm.dateRangeDisplay = ""
                vm.alertSnack("Please input a complete date range.")
            }
        },

        dateRangeChecking(){
            let vm = this;

            let d1 = new Date(vm.dateRange[0]),
                d2 = new Date(vm.dateRange[1]);

            if( d1 != "Invalid Date" && d2 != "Invalid Date" ){
                if( d1 > d2 ){
                    vm.dateRange[2] = vm.dateRange[0];
                    vm.dateRange.splice(0, 1);
                }
                vm.dateRangeDisplay = vm.dateRange.join(" ~ ")
                vm.GetKudosData();
            }else{
                vm.dateRangeDisplay = "";
                vm.dateRange = [];
                vm.alertSnack("Please input a correct date range.")
            }
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },
        ExportKudosData(){
            let vm = this;

            if( vm.selectedKudosObj.length > 0 ){

                vm.dialogLoading = true;
                axios
                .post(process.env.VUE_APP_API_PATH +"/kudos/export", vm.selectedKudosObj, {
                    responseType: 'blob'
                }).then((response) => {
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    }))
                    const link = document.createElement('a')
                    link.href = url;
                    link.setAttribute('download', 'Export_Kudos_Data.xls');
                    document.body.appendChild(link);
                    link.click();
                    vm.dialogLoading = false;
                }).catch(console.error);

            }else{
                vm.alertSnack( "Please select a kudo to export." )
            }
        },

        ExportKudosSlides(){

            let vm = this;

            if( vm.selectedKudosObj.length > 0 ){

                vm.dialogLoading = true;
                axios
                .post(process.env.VUE_APP_API_PATH +"/kudos/exportSlides", vm.selectedKudosObj).then((response) => {
                    vm.dialogLoading = false;
                    if( response.data == "notFound" ){
                        vm.alertSnack("Export Kudos Failed ! Make sure set share permission for template slide.", 5000)
                    }else{
                        vm.selectedKudosObj = []
                        vm.createdSlidesURL = vm.slidesURLPrefix + response.data;
                        vm.dialogCreatedSlides = true;
                    }
                }).catch(console.error);

            }else{
                vm.alertSnack( "Please select a kudo to export." )
            }
        },

        showLeaderBoard(){
            let vm = this;

            vm.dialogLeaderBoard = true;
        },

        closeLeaderBoardDateRangePicker(){
            let vm = this;

            if( vm.leaderBoardDateRange.length > 1 ){
                vm.leaderBoardDateRangePicker = false;
                vm.leaderBoardDateRangeChecking();
            }

        },

        leaderBoardDateRangeInput(){
            const vm = this;

            const displayText = vm.leaderBoardDateRangeDisplay;

            if( displayText.includes(" ~ ") && displayText.length >= 23 ){
                vm.leaderBoardDateRange = displayText.split(" ~ ");
                vm.leaderBoardDateRangeChecking();
            }else{
                vm.leaderBoardDateRangeDisplay = ""
                vm.alertSnack("Please input a complete date range for leaderboard.")
            }
        },

        leaderBoardDateRangeChecking(){
            let vm = this;

            let d1 = new Date(vm.leaderBoardDateRange[0]),
                d2 = new Date(vm.leaderBoardDateRange[1]);

            if( d1 != "Invalid Date" && d2 != "Invalid Date" ){
                if( d1 > d2 ){
                    vm.leaderBoardDateRange[2] = vm.leaderBoardDateRange[0];
                    vm.leaderBoardDateRange.splice(0, 1);
                }
                vm.leaderBoardDateRangeDisplay = vm.leaderBoardDateRange.join(" ~ ")
                vm.GetLeaderboardData();
            }else{
                vm.leaderBoardDateRangeDisplay = "";
                vm.leaderBoardDateRange = [];
                vm.alertSnack("Please input a correct date range for leaderboard.")
            }
        },

        async GetLeaderboardData(){

            let vm = this,
                data = new FormData();

                data.append('date_from', vm.leaderBoardDateRange[0]);
                data.append('date_to', vm.leaderBoardDateRange[1]);


            vm.dialogLoading = true;
            await
            axios
            .post(process.env.VUE_APP_API_PATH +"/kudos/leaderboard", data)
            .then(response => {
                let result = response.data;

                if( result == "failed" ){
                    window.location.href= process.env.VUE_APP_LOGIN_PATH;
                }else{
                    let from_result = result.from,
                        to_result = result.to,
                        prizeEmoji = ["🥇", "🥈", "🥉"];

                    vm.leaderboardFrom = "";
                    vm.leaderboardTo = "";

                    if( from_result.length > 0 ){
                        vm.leaderboardFrom = "<ul class='leaderboardList'>";
                        from_result.forEach(function(val, idx){

                            if( idx < vm.selectedLeaderboardDisplayTop ){
                                if( idx < 3  ){
                                    vm.leaderboardFrom +=  "<li>"+prizeEmoji[idx]+" @" + val.kudos_from_name + " sent " + val.kudos_from_count + " Kudos. </li>"
                                }else{
                                    vm.leaderboardFrom +=  "<li>🏅 @" + val.kudos_from_name + " sent " + val.kudos_from_count + " Kudos. </li>"
                                }
                            }

                        });
                        vm.leaderboardFrom += "</ul>";
                    }else{
                        vm.leaderboardFrom= "There no one sent kudos yet. 😢"
                    }

                    if( to_result.length > 0 ){
                        vm.leaderboardTo = "<ul class='leaderboardList'>";
                        to_result.forEach(function(val, idx){

                            if( idx < vm.selectedLeaderboardDisplayTop ){
                                if( idx < 3 ){
                                    vm.leaderboardTo +=  "<li>"+prizeEmoji[idx]+" @" + val.kudos_to_name + " received " + val.kudos_to_count + " Kudos. </li>"
                                }else{
                                    vm.leaderboardTo +=  "<li>🏅 @" + val.kudos_to_name + " received " + val.kudos_to_count + " Kudos. </li>"
                                }
                            }

                        });
                        vm.leaderboardTo += "</ul>";
                    }else{
                        vm.leaderboardTo= "There no one sent kudos yet. 😢"
                    }

                    if( vm.isInited ){
                        vm.dialogLoading = false;
                    }

                }
            });

        },

        copyLeaderboardContent(ref){
            if( ref == "leaderboardFrom" ){
                this.selectText(this.$refs.leaderboardFrom);
                this.alertSnack("Copied Top Sender.")
            }else{
                this.selectText(this.$refs.leaderboardTo);
                this.alertSnack("Copied Top Receiver.")
            }
            document.execCommand("copy");
            window.getSelection().removeAllRanges();
        },

        selectText(element) {
            var range;
            if (document.selection) {
                // IE
                range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (window.getSelection) {
                range = document.createRange();
                range.selectNode(element);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
        },

        alertSnack(text, timeout=2000){
            this.alertMsgTimeout = timeout;
            this.alertMsg = text;
            this.alertBar = true
        },
        decodeHtml(html) {
            var txt = document.createElement("textarea");
            txt.innerHTML = html;
            return txt.value;
        },
        stripHtml(html){
           let tmp = document.createElement("DIV");
           tmp.innerHTML = html;
           return tmp.textContent || tmp.innerText || "";
        }
    }
}
</script>
